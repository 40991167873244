import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTableMethods } from "../../hooks/useTableMethods";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice.js";
import { apiByRole } from "../../utils/apiByRole.js";
import { getDraftTypeLabel } from "../../utils/drafts.js";
import { CreditCardOutlined, FileExcelOutlined } from "@ant-design/icons";
import PageTitle from "../../components/pageTitle";
import FilterSection from "./components/FilterSection";
import GeneralInfo from "../../components/generalInfo";
import TransactionsDesktop from "./components/TransactionsDesktop";
import TransactionsMobile from "./components/TransactionsMobile";
import moment from "moment";
import { Flex } from "antd";
import CustomButton from "../../components/button/index.js";
import { downloadFile } from "../../services/downloadFile.js";
import { handleDownloadWithBlob } from "../../utils/utils.js";

const Transactions = () => {
  const [generalInfo, setGeneralInfo] = useState({});
  const { isMobile } = useWindowSize();
  const { role } = useSelector(selectUser);
  const apiObj = apiByRole[role].transactions;

  const {
    data = { count: 0, items: [] },
    filters,
    loading,
    fetchData,
    handleFilterChange,
    handlePaginationChange,
    handleSearch,
    handleResetFilter,
    currentPage,
  } = useTableMethods({
    fetchRequest: apiObj.getPayments,
    defaultFilters: { type: "", startDate: "", endDate: "" },
  });

  useEffect(() => {
    apiObj
      .getGeneralInfo({
        startDate: filters.startDate,
        endDate: filters.endDate,
      })
      .then(setGeneralInfo)
      .catch((error) => {
        console.error(error);
      });
  }, [filters]);

  const columns = [
    {
      title: "Project",
      dataIndex: "projectName",
      isSmall: true,
    },
    {
      title: "Company",
      dataIndex: "companyName",
      render: (item) => (
        <Link to={`/contractors/${item.contractorId}`}>{item.companyName}</Link>
      ),
      isSmall: true,
    },
    {
      title: "Service Type",
      dataIndex: "targetType",
      render: ({ targetType }) => <p>{getDraftTypeLabel(targetType)}</p>,
      isSmall: true,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      render: ({ createdAt }) => <p>{moment(createdAt).format("L")}</p>,
      isSmall: true,
    },
    { title: "Price", dataIndex: "price", isSmall: true },
    {
      title: "Type",
      dataIndex: "type",
      render: ({ targetId, type }) => {
        return (
          <Link
            to={
              type === "Draft"
                ? `/projects/${targetId}/details`
                : `/revisions/${targetId}`
            }
          >
            {type.charAt(0) + type.slice(1).toLowerCase()}
          </Link>
        );
      },
      isSmall: true,
    },
  ];
  return (
    <div className="page-content-wrapper">
      <Flex justify="space-between">
        <PageTitle title="Payment Information" />
        {role === "admin" && (
          <CustomButton
            buttonTitle="Export"
            icon={<FileExcelOutlined />}
            onClick={() =>
              handleDownloadWithBlob({
                url: "/admin/transactions/xls-file",
                fileName: "transactions.xls",
              })
            }
          />
        )}
      </Flex>
      <GeneralInfo
        items={[
          {
            title: "Total Balance",
            value: generalInfo.total,
            icon: CreditCardOutlined,
          },
        ]}
      />
      <FilterSection
        handleSearch={handleSearch}
        handleFilterChange={handleFilterChange}
        handleResetFilter={handleResetFilter}
      />
      {!isMobile ? (
        <TransactionsDesktop
          data={data}
          loading={loading}
          handlePaginationChange={handlePaginationChange}
          currentPage={currentPage}
          columns={columns}
        />
      ) : (
        <TransactionsMobile
          data={data}
          fetchData={fetchData}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Transactions;
